<script lang="ts" setup>
import { useMenu } from '~/api/drupal/useMenu'
const { menu: menuMeta } = await useMenu('meta-menu')
const { menu: menuFooter } = await useMenu('footer')
const { menu: menuCompliance } = await useMenu('compliance-menu')
</script>

<template>
  <footer class="footer box">
    <div class="top">
      <div class="container grid">
        <div class="left t-5 tl-3 ds-5">
          <Logo type="footer" />
          <FooterMetaMenu :menu="menuMeta" />
          <FooterSocial />
        </div>

        <div class="right t-6 t-o-1 tl-8 ds-6">
          <FooterMenu :menu="menuFooter" />
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="container">
        <span class="copyright text-xsmall f-medium">{{ $t('footer.copyright') }}</span>
        <FooterComplianceMenu class="menu text-small" :menu="menuCompliance" />
        <LanguageSwitcher class="switcher" />
      </div>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
.footer {
  padding-block: 80px 0;
  background-color: var(--c-background-medium);
}

.left {
  display: flex;
  flex-direction: column;
  @include fluid(gap, 36px, 48px);
}

.right {
  margin-top: 32px;

  @include breakpoint('t') {
    margin-top: 0;
  }
}
.bottom {
  @include fluid(padding-block, 36px, 50px);
  @include fluid(margin-top, 120px, 192px);
  border-top: 1px solid var(--c-line-light);
  .container {
    display: grid;
    grid-template-columns: 100%;
    @include breakpoint(t) {
      grid-template-columns: 1fr auto;
    }
    gap: 32px;
    @include breakpoint('ds') {
      grid-template-columns: auto 1fr auto;
      gap: 48px;
    }
    align-items: center;
  }
}

.copyright {
  margin-top: 27px;
  grid-row: 3;
  @include breakpoint(t) {
    grid-row: 2;
  }
  @include breakpoint(ds) {
    margin-top: 0;

    grid-row: 1;
  }
}

.menu {
  grid-row: 2;
  @include breakpoint(t) {
    grid-row: auto;
  }
  @include breakpoint(ds) {
    margin-left: auto;
  }
}

.switcher {
  justify-self: start;
  @include breakpoint(t) {
    justify-self: end;
  }
}
</style>
